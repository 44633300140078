import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import "./TableInfo.css"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(236, 214, 248, 0.5)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(Product, Description, Unit) {
  return { Product, Description, Unit };
}

const rows = [
  createData(
    'AOD',
    `Aerosol Optical Depth หรือ สารละอองลอยในอากาศ ตรวจวัดปริมาณแสงที่ถูกกระเจิงและดูดซับด้วยสสารละอองลอยในบรรยากาศ ค่ายิ่งสูงสื่อถึงปริมาณสารละอองลอยที่มากขึ้น AOD354  AOD443  AOD500  คือค่า AOD ในช่วงคลื่น 354 443 and 500 นาโนเมตร`,
    'Unitless',
  ),

  createData(
    'SSA',
    `Single Scattering Albedo คือ ความสามารถในการกระเจิงรังสีของฝุ่นละออง เป็นอัตราส่วนระหว่างปริมาณรังสีที่ถูกกระเจิงไปต่อรังสีทั้งหมดที่ถูกลดทอน SSA354  SSA443  SSA500  คือ SSA ในช่วงคลื่น 354 443 และ 500 นาโนเมตร​`,
    'Unitless',
  ),
  createData(
    'UVAI',
    `UV Aerosol Index คือ ดัชนีที่บ่งชี้ถึงลักษณะการดูดกลินของละอองลอย​`,
    'Unitless',
  ),
  createData(
    'VIAI',
    `VI Aerosol Index คือ ดัชนีที่บ่งชี้ถึงขนาดของละอองลาย​​`,
    'Unitless',
  ),
  createData(
    'NO₂',
    `ไนโตรเจนไดออกไซด์ ส่วนใหญ่ปลดปล่อยจากการเผาไหม้ถ่านหิน ผลิตภัณฑ์ปัจจุบันให้ข้อมูลในรูปแบบ คอลัมน์รวมของ NO₂ (NO2Total), NO₂ ในสตราโตสเฟียร์ (NO2Strat), และ NO₂ ในโทรโพสเฟียร์ (NO₂Trop) ซึ่งบ่งบอกความหนาแน่นของคอลัมน์ในแนวตั้ง​​`,
    '1e16 mol/cm²',
  ),
  createData(
    'SO₂',
    `ซัลเฟอร์ไดออกไซด์ ถูกปล่อยออกมาจากแหล่งกำเนิดที่มนุษย์สร้างขึ้นและแหล่งกำเนิดตามธรรมชาติ เช่น การเผาไหม้และการปะทุของภูเขาไฟ ผลิตภัณฑ์ปัจจุบันให้ข้อมูลในรูปแบบ คอลัมน์รวมของ SO₂ บ่งบอกความหนาแน่นของคอลัมน์ในแนวตั้ง​​`,
    '1e16 mol/cm²',
  ),
  createData(
    'O₃',
    `โอโซน มีทั้งประโยชน์และโทษขึ้นอยู่กับชั้นบรรยากาศที่ตรวจพบและความเข้มข้นของในชั้นนั้นๆ ผลิตภัณฑ์ปัจจุบันเป็นข้อมูลในรูปแบบคอลัมน์รวม​​`,
    'DU',
  ),
  createData(
    'PM2.5 (เช็คฝุ่น)',
    `ข้อมูล PM2.5 (เช็คฝุ่น) วิเคราะห์ด้วยเทคโนโลยีปัญญาประดิษฐ์ (AI) เป็นการบูรณาการข้อมูลจากดาวเทียม เช่น Himawari ร่วมกับสถานีตรวจวัด PM 2.5 (กรมควบคุมมลพิษ), ข้อมูลสภาพอากาศ (กรมอุตุนิยมวิทยา) รวมถึงข้อมูลแหล่งกำเนิดฝุ่น เช่น จุดความร้อน เป็นต้น​​`,
    'µg/m³',
  ),
];

export default function TableInfo() {
  return (
    <TableContainer component={Paper} sx={{ width: '100%' }} >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ minWidth: 150, bgcolor: '#fff' }} align="center"><b>ผลิตภัณฑ์</b></StyledTableCell>
            <StyledTableCell sx={{ width: '100%', bgcolor: '#fff' }} align="center" ><b>คำอธิบาย</b></StyledTableCell>
            <StyledTableCell sx={{ width: 150, bgcolor: '#fff' }} align="center" ><b>หน่วย</b></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row" align="center" sx={{ fontWeight: 'bold', color: '#737373' }}>
                {row.Product}
              </StyledTableCell>
              <StyledTableCell align="justify" sx={{ color: '#737373' }}>{row.Description}</StyledTableCell>
              <StyledTableCell align="center" sx={{ color: '#737373' }}>{row.Unit}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}