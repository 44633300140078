import React, { useState, useEffect, useRef } from 'react';

import { Grid, CardMedia, Button, ButtonGroup, colors, Chip, Avatar, Stack } from '@mui/material';

import Axios from 'axios';

import Slider from '@mui/material/Slider';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton
} from "@mui/material";
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableFooter from '@mui/material/TableFooter';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch, Autocomplete, TextField } from '@mui/material/';
import CircularProgress from '@mui/material/CircularProgress';
import { Icon } from "@mui/material/";


import leftarrow from '../img/leftarrow.svg';
import barchart from '../img/barchart.svg';
import time from '../img/time.svg';
import search from '../img/search.svg';
import layer from '../img/layer.svg';
import timeon from '../img/timeon.svg';
import searchon from '../img/searchon.svg';
import layeron from '../img/layeron.svg';


import Select from '@mui/material/Select';
import Fab from '@mui/material/Fab';

import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import picAOD from '../img/AOD.svg';
import picNO2 from '../img/NO2.svg';
import picO3 from '../img/O3.svg';
import picSO2 from '../img/SO2.svg';
import picSSA from '../img/SSA.svg';
import picPM25 from '../img/PM25.svg';
import picUVAI from '../img/UVAI.svg';

import chipAOD from '../img/AOD_chip.png';
import chipNO2 from '../img/NO2_chip.png';
import chipO3 from '../img/O3_chip.png';
import chipSO2 from '../img/SO2_chip.png';
import chipSSA from '../img/SSA_chip.png';
import chipPM25 from '../img/PM25_chip.png';
import chipUVAI from '../img/UVAI_chip.png';


import AODcolor from '../img/AOD_color.png';
import NO2color from '../img/NO2_color.png';
import O3color from '../img/O3_color.png';
import SO2color from '../img/SO2_color.png';
import SSAcolor from '../img/SSA_color.png';
// import PM25color from '../img/PM25_color.png';
import UVAIcolor from '../img/UVAI_color.png';

// import Autocom from '../component/select/autocom';

let sphere = null;
let map = null;

const Home = () => {

  const classes = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);"
      },
      "&.Mui-focused .MuiInputLabel-outlined": {
        color: "purple"
      }
    },
    inputRoot: {
      color: "purple",
      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        // Default left padding is 6px
        paddingLeft: 26
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "green"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "red"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "purple"
      }
    }
  }));

  const gold = '#C89F65';
  const p_color = '#2E2335';
  const urlPath = 'https://air.gistda.or.th/';
  // const urlPath = 'http://127.0.0.1:5000';

  const [var0, setVar0] = useState([]);
  const [var1, setVar1] = useState([]);
  const [lv, setLv] = useState([]);


  const [sid, setSid] = useState(null);
  const [pid, setPid] = useState(null);
  const [aid, setAid] = useState(null);
  const [wid, setWid] = useState(null);
  // let wid = aid ? aid : pid ? pid : sid ? sid : null;

  const [isLoadingPv, setIsLoadingPv] = useState(true);
  const [isLoadingAp, setIsLoadingAp] = useState(true);

  const [value, setValue] = React.useState(new dayjs());
  const [value45, setValue45] = React.useState([]);


  const [stl, setStl] = useState([
    { 'id': 'THA', 'th': 'ราชอาณาจักรไทย' },
    { 'id': 'KHM', 'th': 'ราชอาณาจักรกัมพูชา' },
    { 'id': 'LAO', 'th': 'สาธารณรัฐประชาธิปไตยประชาชนลาว' },
    { 'id': 'MMR', 'th': 'สาธารณรัฐแห่งสหภาพเมียนมาร์' },
    { 'id': 'MYS', 'th': 'มาเลเซีย (ตะวันตก)' },
    { 'id': 'VNM', 'th': 'สาธารณรัฐสังคมนิยมเวียดนาม' }
  ]);
  const [pvl, setPvl] = useState([]);
  const [apl, setApl] = useState([]);

  const [open0, setOpen0] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  // const [options, setOptions] = React.useState([]);
  const loading0 = stl.length === 0;
  const loadingPv = isLoadingPv && open1 && pvl.length === 0;
  const loadingAp = isLoadingAp && open2 && apl.length === 0;
  const [value0, setValue0] = React.useState([]);
  const [value1, setValue1] = React.useState([]);
  const [value2, setValue2] = React.useState([]);

  const [fetchedData, setFetchedData] = useState([]);
  const [avgData, setAvgData] = useState([]);
  const [isLoading1, setisLoading1] = useState(false);
  const [isLoading2, setisLoading2] = useState(false);
  const [showTime, setShowTime] = useState([]);
  const [hours, setHours] = useState([]);
  const [hours56, setHours56] = useState([]);
  const [isLoadingHr, setIsLoadingHr] = useState(true);
  const [selectedTime, setSelectedTime] = useState(0);

  const [minMax, setMinMax] = useState([]);

  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(6);



  // const defaultProps = {
  //   options: top100Films,
  //   getOptionLabel: (option) => option.title,
  // };

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const drawerWidth = 330;

  const theme = useTheme();

  const [open_R1, setOpen_R1] = React.useState(false);
  const [open_R2, setOpen_R2] = React.useState(false);
  const [open_R3, setOpen_R3] = React.useState(false);
  const [open_L, setOpen_L] = React.useState(true);

  const handleDrawerOpen_R1 = () => {
    setOpen_R1(true);
    setOpen_R2(false);
    setOpen_R3(false);
    // handleDrawerClose_R3();
  };

  const handleDrawerClose_R1 = () => {
    setOpen_R1(false);
  };

  const handleDrawerOpen_R2 = () => {
    setOpen_R2(true);
    setOpen_R1(false);
  };

  const handleDrawerClose_R2 = () => {
    setOpen_R2(false);
  };

  const handleDrawerOpen_R3 = () => {
    setOpen_R1(false);
    setOpen_R2(false);
    setOpen_R3(true);
  };

  const handleDrawerClose_R3 = () => {
    setOpen_R3(false);
    toggleMap(selectedTime, var1);
    getData(value.format('YYYY-MM-DD') + " " + value45, aid || pid || sid, aid ? 3 : pid ? 2 : sid ? 1 : 0, var1);
  };

  const handleDrawerOpen_L = () => {
    setOpen_L(true);
    setOpen_R3(false);
    console.log('open');
  };

  const handleDrawerClose_L = () => {
    setOpen_L(false);
    console.log('close');
  };

  const getMinMax = async () => {

    const response = await fetch(
      urlPath + "/rest/getMinMaxDate"
    );
    const result = await response.json();

    setValue(dayjs(result.data.max))
    setMinMax(result.data);

    return dayjs(result.data.max);
  };

  const getPvl = async (id) => {
    // console.log(urlPath + "/rest/getProvName?id=" + sid);
    const response = await fetch(
      urlPath + "/rest/getProvName?id=" + id
    );
    const result = await response.json();

    let d = result.data
    console.log(d);


    setPvl(d);
    setIsLoadingPv(false);

    console.log(pvl);
  };

  const getApl = async (id) => {
    const response = await fetch(
      urlPath + "/rest/getAmpName?id=" + id
    );
    const result = await response.json();

    let d = result.data;

    setApl(d);
    // setIsLoadingAp(false);
  };

  const getData = async (dt, wid, lv, var0) => {
    setisLoading1(true);

    // date = date.add(1, 'day')
    // console.log('fetch ' + JSON.stringify(date1).split('T')[0].slice(1))
    // console.log(`${urlPath}/rest/gettiflist?t0=${JSON.stringify(date1).split('T')[0].slice(1)}&t1=${JSON.stringify(date2).split('T')[0].slice(1)}&var=${var0}`)
    let response = null;

    if (var0 === 'PM25') {
      response = await fetch(
        "https://pm25.gistda.or.th/rest/getPm25byProvince"
        // ?dt=" + dt + "&lv=" + lv + "&type=" + var0 + "&id=" + wid
      );
    } else {
      response = await fetch(
        urlPath + "/rest/getPollution?dt=" + dt + "&lv=" + lv + "&type=" + var0 + "&id=" + wid
      );
    }

    const result = await response.json();

    let r = result.data.map((data) => ({
      value: data.avg_value || data.pm25,
      th: data.country_th || data.province_th || data.district_th || data.pv_tn || data.ap_tn,
    }));
    setFetchedData(r);

    setAvgData(r.reduce((acc, current) => acc + (current.value != null ? current.value : 0), 0) / r.length);
    setShowTime({ d: result.datetimeThai.dateThai, t: result.datetimeThai.timeThai });
    setVar1(var0);

    setisLoading1(false);

  };

  const getTime = async (dt, type1) => {
    setIsLoadingHr(true);

    const response = await fetch(
      urlPath + "/rest/getHours?dt=" + dt + "&type=" + type1
    );
    const result = await response.json();

    let r = result.data.map((data) => ({
      product: data.product,
      dt: dayjs(data.dt).toISOString().replace("T", " ").substring(0, 16),
    }));

    const labels = [...new Set(r.map((item) => item.dt.split(' ')[1]))];

    setHours(labels);
    setValue45(labels[0])
    setIsLoadingHr(false);
  };

  const getTime56 = async (type) => {

    const response = await fetch(
      urlPath + "/rest/getHours56?type=" + type
    );
    const result = await response.json();

    let r = result.data.map((data) => ({
      product: data.product,
      dt: data.dt,
    }));

    const labels = [...new Set(r.map((item) => item.dt))];

    // console.log(labels)

    setHours56(labels);

    // return labels[0];
  };

  const toggleMap = async (i, varr) => {

    await map.Layers.clear();

    if (varr === 'PM25') {
      let layer = await new sphere.Layer(`0`, {
        type: sphere.LayerType.WMS,
        url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/pm25_hourly_raster_24hr/wms",
        zoomRange: { min: 1, max: 12 },
        zIndex: 5,
        opacity: 0.8,
        id: 'layer'
      });

      let layer1 = await new sphere.Layer(`0`, {
        type: sphere.LayerType.WMS,
        url: "https://gistdaportal.gistda.or.th/data2/services/L05_Province_GISTDA_50k_nonlabel/MapServer/WMSServer",
        zoomRange: { min: 1, max: 12 },
        zIndex: 5,
        opacity: 0.8,
        id: 'layer1'
      });

      await map.Layers.add(layer);
      await map.Layers.add(layer1);

    } else {

      let timemax = await hours56[i].replace(':', '').slice(-4);

      let layer = await new sphere.Layer(`airpollution:GK2_GEMS_L2_T-${Math.floor(i / 8)}_${timemax}_${varr}`, {
        type: sphere.LayerType.WMS,
        url: urlPath + "/mapservice/geoserver/airpollution/wms",
        zoomRange: { min: 1, max: 12 },
        zIndex: 5,
        opacity: 0.8,
        id: 'layer'
      });

      await map.Layers.add(layer);
    }
  }

  function valueLabelFormat(value) {
    return `${hours56[value]}`;
  }

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
    // setAge(e.target.value === 'clear' ? '' : e.target.value);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      borderRadius: (props) => {
        return "4px";
      },
      paddingTop: (props) => {
        return "4px";
      },
      paddingBottom: (props) => {
        return "4px";
      },
      paddingLeft: (props) => {
        return "4px";
      },
      paddingRight: (props) => {
        return "4px";
      },
      width: (props) => {
        return "60px";
      },
      textAlign: (props) => {
        return "center";
      },
      color: (props) => {
        let colorset = 1;
        if (['AOD354', 'AOD443', 'AOD500'].includes(var1)) { colorset = 1 }
        if (['NO2Total', 'NO2Strat', 'NO2Trop'].includes(var1)) { colorset = 5 }
        if (['O3Total'].includes(var1)) { colorset = 6 }
        if (['SO2'].includes(var1)) { colorset = 7 }
        if (['SSA354', 'SSA443', 'SSA500'].includes(var1)) { colorset = 2 }
        if (['UVAI'].includes(var1)) { colorset = 3 }
        if (['VIAI'].includes(var1)) { colorset = 4 }
        if (['PM25'].includes(var1)) { colorset = 8 }

        if (!props.value) {
          return "black";
        }

        if (colorset != 2) {
          if (props.value <= [0.5, null, 1.5, 0.0, 0.75 * 1e16, 260, 0.75 * 1e16, 37.5][colorset - 1]) {
            return "black";
          } else {
            return "white";
          }
        }
        if (colorset == 2) {
          if (props.value <= 0.8) {
            return "white";
          } else {
            return "black";
          }
        }
      },
      background: (props) => {
        // [0.000, '#FFCBFF'],
        //   [0.167, '#EDB7FF'],
        //   [0.333, '#DA89F5'],
        //   [0.500, '#B95DD8'],
        //   [0.667, '#9B4FB5'],
        //   [0.833, '#86469C'],
        //   [1.000, '#723288']

        let colorset = 1;
        if (['AOD354', 'AOD443', 'AOD500'].includes(var1)) { colorset = 1 }
        if (['NO2Total', 'NO2Strat', 'NO2Trop'].includes(var1)) { colorset = 5 }
        if (['O3Total'].includes(var1)) { colorset = 6 }
        if (['SO2'].includes(var1)) { colorset = 7 }
        if (['SSA354', 'SSA443', 'SSA500'].includes(var1)) { colorset = 2 }
        if (['UVAI'].includes(var1)) { colorset = 3 }
        if (['VIAI'].includes(var1)) { colorset = 4 }
        if (['PM25'].includes(var1)) { colorset = 8 }

        if (!props.value) {
          return "#EDEDED";
        }

        if (colorset != 2) {
          if (props.value <= [0, null, -1.0, -1.5, 0, 200, 0, 0][colorset - 1]) {
            return "#FFCBFF";
          } else if (props.value <= [0.167, null, -0.167, -1.0, 0.25 * 1e16, 220, 0.25 * 1e16, 15][colorset - 1]) {
            return "#EDB7FF";
          } else if (props.value <= [0.333, null, 0.667, -0.5, 0.50 * 1e16, 240, 0.50 * 1e16, 25][colorset - 1]) {
            return "#DA89F5";
          } else if (props.value <= [0.5, null, 1.5, 0.0, 0.75 * 1e16, 260, 0.75 * 1e16, 37.5][colorset - 1]) {
            return "#B95DD8";
          } else if (props.value <= [0.667, null, 2.33, 0.5, 1.00 * 1e16, 280, 1.00 * 1e16, null][colorset - 1]) {
            return "#9B4FB5";
          } else if (props.value <= [0.833, null, 3.167, 1.0, 1.25 * 1e16, 300, 1.25 * 1e16, null][colorset - 1]) {
            return "#86469C";
          } else if (props.value <= [1.0, null, 4, 1.5, 1.50 * 1e16, 320, 1.50 * 1e16, 75][colorset - 1]) {
            return "#723288";
          } else if (props.value > [1.0, null, 4, 1.5, 1.50 * 1e16, 320, 1.50 * 1e16, 75][colorset - 1]) {
            return "#723288";
          } else {
            return "#EDEDED";
          }
        }
        if (colorset == 2) {
          if (props.value <= 0.8) {
            return "#723288";
          } else if (props.value <= 0.833) {
            return "#86469C";
          } else if (props.value <= 0.867) {
            return "#9B4FB5";
          } else if (props.value <= 0.9) {
            return "#B95DD8";
          } else if (props.value <= 0.933) {
            return "#DA89F5";
          } else if (props.value <= 0.967) {
            return "#EDB7FF";
          } else if (props.value <= 1.0) {
            return "#FFCBFF";
          } else if (props.value > 1.0) {
            return "#FFCBFF";
          } else {
            return "#EDEDED";
          }
        }
      },
    },
    openButton: {
      color: '#fff',
    }
  }));
  const StyledTableCell = (props) => {
    const classes = useStyles(props);
    return (
      <TableCell
        classes={{ root: classes.root }}
      >
        {props.children}
      </TableCell>
    );
  };

  useEffect(() => {
    Axios
      .get('geoserver/airpollution/')
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });


    setVar0('PM25');
    setVar1('PM25');
    getMinMax('AOD443');
    getTime('', 'AOD443');

    setValue(new dayjs());
    // getTime(new dayjs().toISOString().replace("T", " ").substring(0, 10));
    // setValue0({ 'id': 'THA', 'th': 'ราชอาณาจักรไทย' });
    // getPvl(sid);
    getData('', '', 0, 'PM25');
    getTime56('AOD443')

    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', handleWindowResize);

    const url = 'https://vallaris.dragonfly.gistda.or.th/core/api/maps/coverage/1.0-beta/maps/65fa6ac0627f1e6aa9c87c44/tms/{z}/{x}/{y}';

    //-- sphere --//
    sphere = window.sphere;
    map = new sphere.Map({ placeholder: document.getElementById('map-sphere'), zoomRange: { min: 1, max: 20 }, });
    map._this = this;

    map.Event.bind("ready", function () {
      // 91.5499999999999972,-0.0499999999999936 
      // 111.6500000000000057,28.9500000000000064
      map.bound({ minLon: 91.55, minLat: -0.05, maxLon: 111.65, maxLat: 28.95 }, { padding: { top: 7, bottom: 7 } });
      map.Ui.DPad.visible(false);
      map.Ui.LayerSelector.visible(false);
      map.Ui.Geolocation.visible(false);
      map.Ui.Zoombar.visible(false);
      map.Ui.Scale.visible(false);
      map.Ui.Toolbar.visible(false);
      map.Ui.Fullscreen.visible(false);

      // let layer = new sphere.Layer('', {
      //     type: sphere.LayerType.XYZ,
      //     url: 'https://vallaris.dragonfly.gistda.or.th/core/api/maps/coverage/1.0-beta/maps/65fa6ac0627f1e6aa9c87c44/tms',
      //     id: 'layer',
      //     extraQuery: 'api_key=VJJguce0IYzIGdHQeml1Hu7R5lXWNLK84CdeGTQWbQqnwAMhpNk8t5yQwJFKX1Z8'
      // });

      // let timemax = hours56[0].replace(':','').slice(-4);

      let layer = new sphere.Layer(`0`, {
        type: sphere.LayerType.WMS,
        url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/pm25_hourly_raster_24hr/wms",
        zoomRange: { min: 1, max: 12 },
        zIndex: 5,
        opacity: 0.8,
        id: 'layer'
      });

      let layer1 = new sphere.Layer(`0`, {
        type: sphere.LayerType.WMS,
        url: "https://gistdaportal.gistda.or.th/data2/services/L05_Province_GISTDA_50k_nonlabel/MapServer/WMSServer",
        zoomRange: { min: 1, max: 12 },
        zIndex: 5,
        opacity: 0.8,
        id: 'layer1'
      });

      map.Layers.add(layer);
      map.Layers.add(layer1);

      // async function intmap() {
      //   await sleep(5 * 1000);
      //   await toggleMap(1, 'AOD443');
      // }
      // intmap();

      // console.log('add');
    });

  }, []);

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage(parseInt(event));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
    console.log(fetchedData)
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fetchedData.length) : 0;



  return (

    <Box sx={{ position: 'relative', width: windowSize[0], height: windowSize[1], overflow: 'hidden' }}>

      <Box height={64} />

      <Box id="map-sphere" height={windowSize[1] - 64} width={windowSize[0]}></Box>

      <Box
        sx={{
          position: 'fixed ',
          bottom: -5,
          left: (windowSize[0] - 400) / 2,
          zIndex: 10,
        }}
      >
        {var1 === 'AOD443' ? <img src={AODcolor} height={50} width={400} /> : ''}
        {var1 === 'NO2Trop' ? <img src={NO2color} height={50} width={400} /> : ''}
        {var1 === 'O3Total' ? <img src={O3color} height={50} width={400} /> : ''}
        {var1 === 'SO2' ? <img src={SO2color} height={50} width={400} /> : ''}
        {var1 === 'SSA443' ? <img src={SSAcolor} height={50} width={400} /> : ''}
        {/* {var1 === 'PM25' ? <img src={PM25color} height={35} /> : ''} */}
        {var1 === 'UVAI' ? <img src={UVAIcolor} height={50} width={400} /> : ''}
      </Box>

      <Box
        sx={{
          // bgcolor: (theme) =>
          //   theme.palette.mode === 'dark' ? '#101010' : 'grey.600',
          // color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.50'),
          // border: '1px solid',
          // borderColor: (theme) =>
          //   theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
          p: 1,
          borderRadius: 2,
          fontSize: '0.875rem',
          fontWeight: '700',
          position: 'absolute',
          top: 250,
          right: open_R3 ? 0 : -100,
          zIndex: 'tooltip',
        }}
      >

        <Stack direction="column" spacing={1}>
          {/* <Chip disable avatar={<Avatar>PM2.5</Avatar>} label="PM2.5" /> */}
          <Chip style={{ backgroundColor: var1 === 'PM25' ? "#FDD36A" : "" }} variant='contained' onClick={() => { setVar1('PM25'); toggleMap(selectedTime, 'PM25'); }} avatar={<Avatar alt="PM25" src={chipPM25} />} label="PM2.5" />
          <Chip style={{ backgroundColor: var1 === 'AOD443' ? "#FDD36A" : "" }} variant='contained' onClick={() => { setVar1('AOD443'); toggleMap(selectedTime, 'AOD443'); }} avatar={<Avatar alt="AOD" src={chipAOD} />} label="AOD" />
          <Chip style={{ backgroundColor: var1 === 'SSA443' ? "#FDD36A" : "" }} variant='contained' onClick={() => { setVar1('SSA443'); toggleMap(selectedTime, 'SSA443'); }} avatar={<Avatar alt="SSA" src={chipSSA} />} label="SSA" />
          <Chip style={{ backgroundColor: var1 === 'NO2Trop' ? "#FDD36A" : "" }} variant='contained' onClick={() => { setVar1('NO2Trop'); toggleMap(selectedTime, 'NO2Trop'); }} avatar={<Avatar alt="NO2" src={chipNO2} />} label="NO₂" />
          <Chip style={{ backgroundColor: var1 === 'SO2' ? "#FDD36A" : "" }} variant='contained' onClick={() => { setVar1('SO2'); toggleMap(selectedTime, 'SO2'); }} avatar={<Avatar alt="SO2" src={chipSO2} />} label="SO₂" />
          <Chip style={{ backgroundColor: var1 === 'O3Total' ? "#FDD36A" : "" }} variant='contained' onClick={() => { setVar1('O3Total'); toggleMap(selectedTime, 'O3Total'); }} avatar={<Avatar alt="O3" src={chipO3} />} label="O₃" />
          <Chip style={{ backgroundColor: var1 === 'UVAI' ? "#FDD36A" : "" }} variant='contained' onClick={() => { setVar1('UVAI'); toggleMap(selectedTime, 'UVAI'); }} avatar={<Avatar alt="UVAI" src={chipUVAI} />} label="UVAI" />
          {/* <Chip avatar={<Avatar alt="AOD" src="/static/images/avatar/1.jpg" />} label="AOD" /> */}
        </Stack>
      </Box>

      <Box sx={{
        // bgcolor: '#E2E9E9',
        color: '#000',
        // border: '1px solid',
        borderColor: (theme) =>
          theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        // opacity: 0.5,
        p: 2,
        s: 2,
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
        position: 'absolute',
        // maxHeight: 600,
        bottom: (open_R3 && (var0 !== 'PM25' || var1 !== 'PM25')) ? 30 : -110,
        left: windowSize[0] * 0.01 / 2,
        zIndex: 'modal',
        width: windowSize[0] * 0.99,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        spacing: 1,
        alignItems: 'center',
      }}>
        <Box padding={1}>
          <Fab disabled={selectedTime === 0} size='small' onClick={() => {
            setSelectedTime(selectedTime - 1);
            toggleMap(selectedTime - 1, var1);
          }}>
            {'<'}
          </Fab>
        </Box>

        <Box padding={1} width={'100%'}>
          <Slider
            // disabled={var1 === 'PM25'}
            sx={{
              '& .MuiSlider-thumb': {
                color: p_color
              },
              '& .MuiSlider-track': {
                color: p_color
              },
              '& .MuiSlider-rail': {
                color: p_color
              },
              '& .MuiSlider-active': {
                color: p_color
              },
            }}
            onChange={(e, val) => {
              setSelectedTime(val);
              // toggleMap(val, var1);
            }}
            onChangeCommitted={(e, val) => {
              setSelectedTime(val);
              toggleMap(val, var1);
            }}
            value={selectedTime}
            defaultValue={56}
            valueLabelDisplay="auto"
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
            shiftStep={1}
            step={1}
            marks
            min={0}
            max={55}
          />
        </Box>

        <Box padding={1}>
          <Fab disabled={selectedTime === 55} size='small' onClick={() => {
            setSelectedTime(selectedTime + 1);
            toggleMap(selectedTime + 1, var1);
          }}>
            {'>'}
          </Fab>
        </Box>

      </Box>

      <Box
        sx={{
          position: 'absolute',
          zIndex: 'modal',
          top: 100,
          left: open_L && !open_R3 ? (windowSize[0] < windowSize[1] ? 350 : 432) : 10,
        }}
      >
        <Fab onClick={() => open_L && !open_R3 ? handleDrawerClose_L() : handleDrawerOpen_L()}
          variant="outlined"
          sx={{
            bgcolor: p_color,
            "&:hover": {
              backgroundColor: "#86469C"
            },
            // position: 'absolute',
            // zIndex: 10,
            // top: 0,
            // // left: open_L && !open_R3 ? windowSize[0] * 0.25 > 400 ? windowSize[0] * 0.25 : 432 : 10,
            // left: open_L && !open_R3 ? windowSize[0] * 0.25 : 10,
          }}
          style={{
            justifyContent: 'center',

            margin: 0
          }}>
          {/* <Icon> */}
          <img src={open_L && !open_R3 ? leftarrow : barchart} height={20} width={20} />
          {/* </Icon> */}

        </Fab>
      </Box>

      <Box
        sx={{
          bgcolor: 'rgba(255,255,255,0.5)',
          color: '#000',
          // border: '1px solid',
          borderColor: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
          // opacity: 0.5,
          p: 2,
          borderRadius: 2,
          fontSize: '0.875rem',
          fontWeight: '700',
          position: 'absolute',
          // maxHeight: 600,
          top: 80,
          left: open_L && !open_R3 ? windowSize[0] * 0.01 : windowSize[0] * 0.25 > 400 ? -windowSize[0] * 0.3 : -500,
          zIndex: 10,
          width: windowSize[0] * 0.25,
          minWidth: 400,
          // minWidth: 350,
          "&::-webkit-scrollbar": {
            width: 8,
            height: 8
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#BCBCBC"
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#606060",
            borderRadius: 0
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#707070"
          }
        }}
        style={{
          overflowY: "auto",
          maxHeight: windowSize[1] - 64,
          // display: "flex",
          // flexGrow: 1,
          // flexDirection: "column",

        }}>
        <Grid spacing={1} direction={'column'} container
          // alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Typography fontWeight={700} color={'#434343'}>
              {/* {aid || pid || sid} <br /> */}
              พื้นที่: {aid ? value2.ap_tn : pid ? value1.pv_tn : sid ? value0.th : 'เอเชียตะวันออกเฉียงใต้ภาคพื้นทวีป'}<br />
              ข้อมูล ณ {showTime.d}
            </Typography>
            <Typography variant="caption">
              {selectedTime == 0 ? 'อัพเดทล่าสุด' : ''} ณ {showTime.t} (UTC)
            </Typography>


          </Grid>
          <Grid item>
            <Paper sx={{ height: 150, boxShadow: '4' }}>
              {/* <Grid width={'100%'} padding={1} container> */}
              <Box display={'flex'} flexDirection={'column'} alignContent={'center'} paddingTop={2}>
                <Typography align={'center'}>ค่าเฉลี่ย {var1}</Typography>
                <Box
                  sx={{ display: 'flex', p: 1, bgcolor: 'background.paper', borderRadius: 1 }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    {var1 === 'AOD443' ? <img src={picAOD} height={120} /> : ''}
                    {var1 === 'NO2Trop' ? <img src={picNO2} height={120} /> : ''}
                    {var1 === 'O3Total' ? <img src={picO3} height={120} /> : ''}
                    {var1 === 'SO2' ? <img src={picSO2} height={120} /> : ''}
                    {var1 === 'SSA443' ? <img src={picSSA} height={120} /> : ''}
                    {var1 === 'PM25' ? <img src={picPM25} height={120} /> : ''}
                    {var1 === 'UVAI' ? <img src={picUVAI} height={120} /> : ''}
                  </Box>
                  <Box>
                    <Typography align='right' padding={1} variant="h1" fontWeight='fontWeightMedium' sx={{
                      fontStyle: 'italic',
                      backgroundcolor: "primary",
                      backgroundImage: `linear-gradient(54deg, #382D40, #86469C, #BC98C8)`,
                      backgroundSize: "100%",
                      backgroundRepeat: "repeat",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",

                    }}
                    >
                      {isLoading1 ? '' : avgData ? (Number(avgData) / (var1 === 'SO2' || var1 === 'NO2Trop' ? 10000000000000000 : 1)).toFixed(2) : ''}
                    </Typography>
                    <Typography>
                      {isLoading1 ? '' : avgData ? '' : 'ไม่มีข้อมูล'}
                    </Typography>
                    {/* <Typography>{isLoading1 ? '' : var1 === 'SO2' || var1 === 'NO2Trop' ? 'x1e16' : ''}</Typography> */}
                  </Box>
                </Box>

                <Box display={'flex'} flexDirection={'row'} alignContent={'center'}>
                </Box>

              </Box>

              {/* </Grid> */}

            </Paper>

          </Grid>
          <Grid item>
            <Paper sx={{ width: '100%', minHeight: 210, boxShadow: '4' }}>
              <TableContainer sx={{
                maxHeight: windowSize[1] - 375,
                "&::-webkit-scrollbar": {
                  width: 8,
                  height: 8
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#BCBCBC"
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#606060",
                  borderRadius: 3
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#707070"
                }
              }}>
                <Table
                  stickyHeader
                  aria-labelledby="tableTitle"
                  size={'small'}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>ลำดับ</TableCell>
                      <TableCell>พื้นที่</TableCell>
                      <TableCell>ค่าเฉลี่ย</TableCell>
                    </TableRow>
                  </TableHead>
                  {isLoading1 ?
                    <TableBody>
                      <TableRow>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                      </TableRow>
                    </ TableBody>
                    :
                    <TableBody>
                      {(rowsPerPage > 0
                        ? fetchedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : fetchedData
                      ).map((row, index) => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {index + 1 + page * rowsPerPage}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.th}
                            {/* {row.dt.match(`[0-9]{6}\/[0-9]{2}`)} {row.dt.match(`_[0-9]{4}_`)} UTC */}
                          </TableCell>
                          <TableCell align="center">
                            <StyledTableCell value={row.value} >
                              <Typography>{row.value ? (Number(row.value) / (var1 === 'SO2' || var1 === 'NO2Trop' ? 10000000000000000 : 1)).toFixed(2) : 'ไม่มีข้อมูล'}</Typography>
                              {/* {isLoading1 ? '' : var1 === 'SO2' || var1 === 'NO2Trop' ? ' x1e16' : ''} */}
                            </StyledTableCell>

                          </TableCell>
                        </TableRow>
                      ))}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                  }


                </Table>
              </TableContainer>
              {fetchedData.length > 6 && rowsPerPage !== -1 ? <Button sx={{ width: '100%' }} style={{ backgroundColor: "#6E5777", }} variant='contained' onClick={() => { handleChangeRowsPerPage1(-1) }}>ดูเพิ่มเติม</Button> : ''}
              {fetchedData.length > 6 && rowsPerPage === -1 ? <Button sx={{ width: '100%' }} style={{ backgroundColor: "#6E5777", }} variant='contained' onClick={() => { handleChangeRowsPerPage1(6) }}>ย่อลง</Button> : ''}

            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          bgcolor: p_color,
          // color: '#000',
          // border: '1px solid',
          borderColor: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
          opacity: 1,
          p: 2,
          borderTopLeftRadius: 5,
          borderBottomLeftRadius: 5,
          fontSize: '0.875rem',
          fontWeight: '700',
          position: 'absolute',
          width: 54,
          height: 110,
          top: 131,
          right: 0 + parseInt(1 * (open_R1 || open_R2) * drawerWidth),
          zIndex: 1,
        }}
      >
        <Grid spacing={1} direction={'column'} container>
          <IconButton
            sx={{ color: open_R1 ? '#EE0' : '#000000' }}
            aria-label="open drawer"
            edge="end"
            onClick={open_R1 ? handleDrawerClose_R1 : handleDrawerOpen_R1}
          // sx={{ ...(open && { display: 'none' }) }}
          >
            <img src={open_R1 ? searchon : search} />
          </IconButton>
          {/* <IconButton
            sx={{ color: open_R2 ? '#EE0' : '#000000' }}
            aria-label="open drawer"
            edge="end"
            onClick={open_R2 ? handleDrawerClose_R2 : handleDrawerOpen_R2}
          // sx={{ ...(open && { display: 'none' }) }}
          >
            <img src={open_R2 ? layeron : layer} />
          </IconButton> */}
          <IconButton
            sx={{ color: open_R3 ? '#EE0' : '#000000' }}
            // aria-label="open drawer"
            edge="end"
            onClick={open_R3 ? handleDrawerClose_R3 : handleDrawerOpen_R3}
          // sx={{ ...(open && { display: 'none' }) }}
          >
            <img src={open_R3 ? timeon : time} />
          </IconButton>
        </Grid>

      </Box>

      <Box sx={{
        top: 64,
        right: 0,
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
        },
      }}
      // variant="persistent"
      // anchor="right"
      // open={open_R1||open_R2}
      >

        <Box
          sx={{
            bgcolor: p_color,
            color: '#000',
            // border: '1px solid',
            borderColor: (theme) =>
              theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
            opacity: 1,
            p: 0,
            paddingLeft: 2,
            // borderRadius: 2,
            fontSize: '0.875rem',
            fontWeight: '700',
            position: 'absolute',
            width: drawerWidth,
            height: windowSize[1],
            // maxHeight: windowSize[1],
            top: 64,
            right: open_R1 ? 0 : -drawerWidth,
            zIndex: 'modal',
          }}
        >
          <Box sx={{
            color: 'white',
            bgcolor: p_color,
            // height: '100%',
            width: '100%',
            "&::-webkit-scrollbar": {
              width: 8,
              height: 8
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#BCBCBC"
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#606060",
              borderRadius: 0
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#707070"
            }
          }}
            style={{
              overflowY: "auto",
              maxHeight: windowSize[1] - 64,
              // display: "flex",
              flexGrow: 1,
              flexDirection: "column",

            }}>
            {/* <List> */}
            {/* <ListItem> */}
            <Box paddingTop={1} paddingBottom={1}>
              <Grid spacing={1} direction={'column'} container>
                <Grid item>
                  <div>ประเทศ</div>

                  <Autocomplete
                    disabled={var0 === 'PM25'}
                    value={value0}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      setValue0(newValue);
                      setSid(newValue ? newValue.id : null);

                      if (newValue) {
                        getPvl(newValue.id);
                        setWid(newValue.id);
                        console.log(newValue.id);
                      }
                      setPvl([]);
                      setApl([]);
                      setPid(null);
                      setAid(null);
                      setValue1([]);
                      setValue2([]);
                    }}
                    sx={{ width: 300, bgcolor: '#6E5777', color: '#FFFFFF' }}
                    open={open0}
                    onOpen={() => {
                      setOpen0(true);
                    }}
                    onClose={() => {
                      setOpen0(false);
                    }}
                    isOptionEqualToValue={(option, value) => option.th === value.th}
                    getOptionLabel={(option) => option.th || "ทุกประเทศ"}
                    options={stl}
                    loading={loading0}
                    noOptionsText={'ไม่มีข้อมูล'}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          input: {
                            color: 'rgba(255,255,255,0.7)',
                          }
                        }}
                        {...params}
                        // label="เลือกประเทศ"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading0 ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />

                  {/* <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">ประเทศ</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      label="ประเทศ"
                      onChange={handleChange}

                    >
                      <MenuItem key='clear' value='clear'>Clear selection</MenuItem>

                      <MenuItem value={10}>ประเทศไทย</MenuItem>
                      <MenuItem value={20}>สาธารณรัฐประชาธิปไตยประชาชนลาว</MenuItem>
                      <MenuItem value={30}>สาธารณรัฐแห่งสหภาพเมียนมา</MenuItem>
                    </Select>
                  </FormControl>
                </Box> */}
                </Grid>

                <Grid item>
                  <div>จังหวัด</div>
                  <Box sx={{ minWidth: 120 }}>
                    <Autocomplete
                      disabled={var0 === 'PM25'}
                      value={value1}
                      onChange={(event, newValue) => {
                        console.log(newValue);
                        setValue1(newValue);
                        setPid(newValue ? newValue.pv_id : null);

                        if (newValue) {
                          getApl(newValue.pv_id);
                          setWid(newValue.pv_id);
                          console.log(newValue.pv_id);
                        }
                        setValue2([]);
                        setAid(null);
                      }}
                      sx={{ width: 300, bgcolor: '#6E5777' }}
                      open={open1}
                      onOpen={() => {
                        setOpen1(true);
                      }}
                      onClose={() => {
                        setOpen1(false);
                      }}
                      isOptionEqualToValue={(option, value) => option.pv_tn === value.pv_tn}
                      getOptionLabel={(option) => option.pv_tn || "ทุกจังหวัด"}
                      options={pvl}
                      loading={loadingPv}
                      noOptionsText={'ไม่มีข้อมูล'}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            input: {
                              color: 'rgba(255,255,255,0.7)',
                            }
                          }}
                          {...params}
                          // label="เลือกจังหวัด"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingPv ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />

                    {/* <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">เลือกจังหวัด</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      label="จังหวัด"
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>จังหวัด</MenuItem>
                      <MenuItem value={20}></MenuItem>
                      <MenuItem value={30}></MenuItem>
                    </Select>
                  </FormControl> */}
                  </Box>
                </Grid>

                <Grid item>
                  <div>อำเภอ</div>
                  <Autocomplete
                    disabled={var0 === 'PM25'}
                    value={value2}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      setValue2(newValue);
                      if (newValue) {
                        setAid(newValue ? newValue.ap_id : null);
                        setWid(newValue.ap_id);
                        console.log(newValue.ap_id);
                      }
                    }}
                    sx={{ width: 300, bgcolor: '#6E5777' }}
                    open={open2}
                    onOpen={() => {
                      setOpen2(true);
                    }}
                    onClose={() => {
                      setOpen2(false);
                    }}
                    isOptionEqualToValue={(option, value) => option.ap_tn === value.ap_tn}
                    getOptionLabel={(option) => option.ap_tn || "ทุกอำเภอ"}
                    options={apl}
                    loading={loadingAp}
                    noOptionsText={'ไม่มีข้อมูล'}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          input: {
                            color: 'rgba(255,255,255,0.7)',
                          }
                        }}
                        {...params}
                        // label="เลือกอำเภอ"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingAp ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  {/* <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">เลือกอำเภอ</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      label="อำเภอ"
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>อำเภอ</MenuItem>
                      <MenuItem value={20}></MenuItem>
                      <MenuItem value={30}></MenuItem>
                    </Select>
                  </FormControl>
                </Box> */}
                </Grid>
              </Grid>
            </Box>
            {/* </ListItem> */}
            <Divider color="#FDA228" sx={{
              height: 2, width: drawerWidth * 0.91
            }}
            />
            {/* <ListItem> */}
            <Box paddingTop={1} paddingBottom={1}>
              <Grid>
                <div>ชั้นข้อมูลมลพิษทางอากาศ</div>
                <Box width={100} sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  s: 1,
                  m: 1,
                  spacing: 1,
                }} >
                  <Box padding={0.2}><Button style={{ backgroundColor: var0 === 'AOD443' ? "#FDD36A" : "#6E5777" }} variant='contained' onClick={() => { setVar0('AOD443'); getTime(value.format('YYYY-MM-DD'), 'AOD443'); }}>AOD</Button></Box>
                  <Box padding={0.2}><Button style={{ backgroundColor: var0 === 'SSA443' ? "#FDD36A" : "#6E5777" }} variant='contained' onClick={() => { setVar0('SSA443'); getTime(value.format('YYYY-MM-DD'), 'SSA443'); }}>SSA</Button></Box>
                  <Box padding={0.2}><Button style={{ backgroundColor: var0 === 'NO2Trop' ? "#FDD36A" : "#6E5777" }} variant='contained' onClick={() => { setVar0('NO2Trop'); getTime(value.format('YYYY-MM-DD'), 'NO2Trop'); }}>NO₂</Button></Box>
                  <Box padding={0.2}><Button style={{ backgroundColor: var0 === 'SO2' ? "#FDD36A" : "#6E5777" }} variant='contained' onClick={() => { setVar0('SO2'); getTime(value.format('YYYY-MM-DD'), 'SO2'); }}>SO₂</Button></Box>
                </Box>
                <Box width={100} sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  s: 1,
                  m: 1,
                }} >
                  <Box padding={0.2}><Button style={{ backgroundColor: var0 === 'O3Total' ? "#FDD36A" : "#6E5777" }} variant='contained' onClick={() => { setVar0('O3Total'); getTime(value.format('YYYY-MM-DD'), 'O3Total'); }}>O₃</Button></Box>
                  <Box padding={0.2}><Button style={{ backgroundColor: var0 === 'UVAI' ? "#FDD36A" : "#6E5777" }} variant='contained' onClick={() => { setVar0('UVAI'); getTime(value.format('YYYY-MM-DD'), 'UVAI'); }}>UVAI</Button></Box>
                  <Box padding={0.2}><Button style={{ backgroundColor: var0 === 'PM25' ? "#FDD36A" : "#6E5777" }} variant='contained' onClick={() => { setVar0('PM25'); getTime(value.format('YYYY-MM-DD'), 'PM25'); }}>PM2.5</Button></Box>
                </Box>
              </Grid>
            </Box>
            {/* </ListItem> */}
            <Divider color="#FDA228" sx={{
              height: 2, width: drawerWidth * 0.91
            }}
            />            {/* <ListItem> */}
            <Box paddingTop={1} paddingBottom={1}>
              <div>กำหนดวันเอง</div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>

                <DatePicker
                  disabled={var0 === 'PM25'}
                  sx={{ bgcolor: '#D9D9D9', width: '300px', }}
                  minDate={dayjs(minMax.max).subtract(7, 'days')}
                  maxDate={dayjs(minMax.max)}
                  // label="เลือกวัน"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue)
                    getTime(newValue.format('YYYY-MM-DD'), var0)
                  }}
                />
                <Autocomplete
                  disabled={var0 === 'PM25'}
                  value={value45}
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    setValue45(newValue);
                  }}
                  sx={{ width: 300, bgcolor: '#6E5777' }}

                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionLabel={(option) => option || "เลือกเวลา"}
                  options={hours}
                  noOptionsText={'ไม่มีข้อมูล'}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="เลือกเวลา"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoadingHr ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              {/* </ListItem> */}
              <Divider />
              {/* <ListItem> */}
              {/* <Button variant="contained" disabled={(!var0 || !value45)} style={{ backgroundColor: value45 ? "#E58B38" : "grey" }} onClick={() => { */}
              <Button variant="contained" disabled={!(var0 === 'PM25') && (!var0 || !value45)} style={{ backgroundColor: value45 || (var0 === 'PM25') ? "#E58B38" : "grey" }} onClick={() => {
                getData(value.format('YYYY-MM-DD') + " " + value45, aid || pid || sid, aid ? 3 : pid ? 2 : sid ? 1 : 0, var0);
                // console.log(value45, aid || pid || sid, aid ? 3 : pid ? 2 : sid ? 1 : 0, var0);
                toggleMap(hours56.indexOf(value.format('YYYY-MM-DD') + " " + value45), var0);
                setSelectedTime(hours56.indexOf(value.format('YYYY-MM-DD') + " " + value45));
                console.log(hours56.indexOf(value.format('YYYY-MM-DD') + " " + value45))
              }
              }>กดเพื่อแสดงข้อมูล</Button>
            </Box>

            {/* </ListItem> */}
            <Divider />
            {/* </List> */}

          </Box>
        </Box>

      </Box >

      <Box sx={{
        top: 64,
        right: 0,
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
        },
      }}
      // variant="persistent"
      // anchor="right"
      // open={open_R1||open_R2}
      >

        <Box
          sx={{
            bgcolor: p_color,
            color: '#000',
            // border: '1px solid',
            borderColor: (theme) =>
              theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
            opacity: 1,
            p: 2,
            // borderRadius: 2,
            fontSize: '0.875rem',
            fontWeight: '700',
            position: 'absolute',
            width: drawerWidth,
            height: windowSize[1],
            // maxHeight: windowSize[1],
            top: 64,
            right: open_R2 ? 0 : -drawerWidth,
            // zIndex: 'modal',
          }}
        >
          <Box sx={{
            bgcolor: p_color,
            height: '100%',
            width: '100%',
          }} >
            <Grid spacing={1} direction={'column'} container>
              <Grid item>
                <div>ชั้นข้อมูล</div>
                <Divider />
                <div>ชั้นข้อมูลภัยพิบัติ</div>

                <Box sx={{ minWidth: 120, bgcolor: '#6E5777', padding: 1 }}>
                  <FormControlLabel control={<Switch defaultChecked />} label="จุดความร้อน VIIRS" />
                </Box>

                <div>ชั้นข้อมูลทั่วไป</div>

                <Box sx={{ minWidth: 120, bgcolor: '#6E5777', padding: 1 }}>
                  <FormControlLabel control={<Switch defaultChecked />} label="ขอบเขตการปกครอง" />
                  <Box sx={{ minWidth: 120, bgcolor: '#A787BE', padding: 1 }}>
                    <FormControlLabel control={<Switch defaultChecked />} label="จังหวัด" />
                  </Box>
                  <Box sx={{ minWidth: 120, bgcolor: '#A787BE', padding: 1 }}>
                    <FormControlLabel control={<Switch defaultChecked />} label="อำเภอ" />
                  </Box>
                </Box>
              </Grid>
            </Grid>

          </Box>
        </Box>

      </Box>

      {/* <Drawer

        sx={{
          top: 76,
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
        containerStyle={{ height: 'calc(100% - 67px)', top: 67 }}
      >

        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Paper>
          <Box sx={{
            bgcolor: '#A560BC',
            height: '100%',
            width: '100%',
          }} >
            <Grid spacing={1} direction={'column'} container>
              <Grid item>
                <div>ประเทศ</div>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">ประเทศ</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      label="ประเทศ"
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>ประเทศไทย</MenuItem>
                      <MenuItem value={20}>สาธารณรัฐประชาธิปไตยประชาชนลาว</MenuItem>
                      <MenuItem value={30}>สาธารณรัฐแห่งสหภาพเมียนมา</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item>
                <div>จังหวัด</div>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">เลือกจังหวัด</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      label="จังหวัด"
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>จังหวัด</MenuItem>
                      <MenuItem value={20}></MenuItem>
                      <MenuItem value={30}></MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item>
                <div>อำเภอ</div>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">เลือกอำเภอ</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      label="อำเภอ"
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>อำเภอ</MenuItem>
                      <MenuItem value={20}></MenuItem>
                      <MenuItem value={30}></MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Divider />

            <Grid>
              <div>ชั้นข้อมูลมลพิษทางอากาศ</div>
              
            </Grid>

            <Divider />
            <div>กำหนดวันเอง</div>
            <Divider />

          </Box>


        </Paper>

      </Drawer> */}


      {/* <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: { md: '100px', xs: '50px' },
          background: 'linear-gradient(to bottom, transparent, #000000)',
          // color: 'white',
        }}
      >

      </Box> */}
    </Box >

    // <Grid>
    //     <Box id="map-sphere" height={windowSize[1]} width={windowSize[0]}></Box>
    // </Grid>


  );
}

export default Home;
